import React from "react"
import "../styles/values.scss"
import Adapt from "../images/adapt.png"
import Grow from "../images/grow.png"
import Benefit from "../images/benefit.png"

const ValuesDiagram = () => {
  return (
    <div className= "values-hero">
    <div className="section" id="implementation">
      <div className="outer-container">
      {/* <a href="" id="values" className="anchor" style={{marginTop:"-60px"}}></a> */}
      <div className="overlay" style={{marginTop:"10px"}}>

        <div className="inner-container" style={{maxWidth:"800px"}}>
            <h2 style={{ fontWeight: "bold", color: "#ffffff", marginTop:"-20px" }}>
          Our Culture
        </h2>
          <div className="row">
            <div className="values-option-div" id="encapsulatev">
              <div className="val-foreground-text" style={{flexDirection:"column"}}><strong style={{paddingTop:"0.5rem"}}>Adapt</strong>
              <p style={{fontSize:"12px", width:"90%"}}>Like water, we adapt. We seek to be fluid, transformative...</p>
              </div>
              <div className="val-background-text"></div>
              <div  className="values-text-images">
                  <div  className="val-modernisation-text">
                  {/* <p style={{fontSize:"18px", marginTop:"-30px"}}><strong>Adapt</strong></p> */}
                    </div>
                    <div  className="val-modernisation-text">
                      <img src={Adapt}  style={{
                          marginTop:"-50px",
                      width: "20%",
                  }}></img>
                  </div>
              <div className="val-modernisation-text">
                <p>
                Like water, we adapt. We seek to be fluid, transformative, and 
                beneficial to all teams, technology vendors, and partners as we 
                collaborate with our customers to help them embrace the ever-changing 
                demands along their digital transformation <br/> journey. 
                </p>
              </div>
            </div>
            </div>

            <div style={{width:"30px"}}></div>

            <div className="values-option-div" id="rehostv">
              <div className="val-foreground-text"  style={{flexDirection:"column"}}><strong style={{paddingTop:"0.5rem"}}>Grow</strong>
              <p style={{fontSize:"12px", width:"90%"}}>Our engineers learn and grow in a passionate and challenging...</p>
              </div>
              <div className="val-background-text"></div>
              <div  className="values-text-images">
                  <div  className="val-modernisation-text">
                    {/* <p style={{fontSize:"18px", marginTop:"-30px"}}><strong>Grow</strong></p> */}
                    </div>
                    <div  className="val-modernisation-text">
                      <img src={Grow}  style={{
                          marginTop:"-30px",
                      width: "25%",
                  }}></img>
                  </div>
                  <div  className="val-modernisation-text">
                  
                    <p>
                    Our engineers learn and grow in a passionate and challenging environment, 
                    while our customers reap the benefits of proven experience and innovative 
                    ideas. We share ideas, solve problems, and create opportunities.                     
                     </p></div>

              </div>
            </div>

            <div style={{width:"30px"}}></div>

            <div className="values-option-div" id="replatformv">
              <div className="val-foreground-text"   style={{flexDirection:"column"}}><strong style={{paddingTop:"0.5rem"}}>Benefit</strong>
              <p style={{fontSize:"12px", width:"90%"}}>We empower communities we serve to thrive, including...</p>
              </div>
              <div className="val-background-text"></div>
              <div  className="values-text-images">
                  <div  className="val-modernisation-text">
                  {/* <p style={{fontSize:"18px", marginTop:"-30px"}}><strong>Benefit</strong></p> */}
                    </div>
                    <div  className="val-modernisation-text">
                      <img src={Benefit}  style={{
                          marginTop:"-30px",
                      width: "30%",
                  }}></img>
                  </div>
              <div className="val-modernisation-text">
                <p>
                
                We empower communities we serve to thrive, including our employees, our customers, 
                and our partners. We believe in helping our employees improve all aspects of their 
                personal well-being and their career paths. We give back to our neighbourhoods and
                <br/> communities. 
                
                </p>
              </div>
              </div>
              </div>
            </div>
          </div>
          
          
          
          {/* <div className="section" id="vortex" style={{backgroundImage:"none", backgroundColor:"transparent", opacity:"1"}}>
          <div className="column">
            <p>
              Working like an extension to your team Metsi help customers and
              partners through uncertain times to stay ahead of the curve.
              Through a shared vision of success, Metsi provide the solid
              foundation for your digital transformation journey.
            </p>
            <p>
              <strong>
                Metsi Technologies brand continues to gather momentum and is
                synonymous with <br></br>
                <span style={{color:"#2bb9c9"}}>
                  “Project success”, technical excellence and agility.
                </span>
              </strong>
            </p>
          </div>
      </div> */}


        </div>
        </div>
      </div>
    </div>
  )
}

export default ValuesDiagram
