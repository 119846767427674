import React from "react"
import "../styles/ButtonBAlt.scss"

const ButtonB = ({ children, onClick, href }) => {
  return (
    <a href={href} className="btnB btnB-1">
      <svg>
        <rect x="1" y="1" rx="5" ry="5" fill="none" width="99%" height="95%" />
      </svg>
      {children}
    </a>
  )
}

export default ButtonB
